<template>
    <div class="legal">
        <h1>{{ $t("legal.imprint") }}</h1>
        <p>{{ $t("legal.infoAccordingTo") }}</p>
        <p>
            {{ $t("legal.contactPerson") }}<br />
            {{ $t("legal.address") }}<br />
            {{ $t("legal.location") }}<br />
            {{ $t("legal.country") }}
        </p>
        <p>
            +4369915010302 | mail@arminnovacek.com
        </p>

        <h2>{{ $t("legal.euDisputeResolution") }}</h2>
        <p>{{ $t("legal.odrInfo") }}</p>
        <p>{{ $t("legal.notObligatedToParticipate") }}</p>

        <h2>{{ $t("legal.liabilityForContent") }}</h2>
        <p>{{ $t("legal.contentDevelopment") }}</p>
        <p>{{ $t("legal.removalAndBlockingOfInformation") }}</p>
        <p>{{ $t("legal.reportProblematicContent") }}</p>

        <h2>{{ $t("legal.liabilityForLinks") }}</h2>
        <p>{{ $t("legal.noLiabilityForLinkedWebsites") }}</p>
        <p>{{ $t("legal.reportProblematicLinks") }}</p>

        <h2>{{ $t("legal.copyrightNotice") }}</h2>
        <p>{{ $t("legal.copyrightInfo") }}</p>
        <p>{{ $t("legal.reportCopyrightViolation") }}</p>

        <h2>{{ $t("legal.imageCredits") }}</h2>
        <p>{{ $t("legal.imageCreditNotice") }}</p>
        <p>{{ $t("legal.photographer") }}</p>
        <p>{{ $t("legal.allTextsProtected") }}</p>

        <p>{{ $t("legal.source") }}</p>

        <h2>{{ $t("legal.useOfAIImagesFromCanva") }}</h2>
        <p>{{ $t("legal.canvaInfo") }}</p>

        <h2>{{ $t("legal.googleWebFonts") }}</h2>
        <p>{{ $t("legal.webFontsInfo") }}</p>
    </div>
</template>

<script>
export default {
    name: "LegalView",
}
</script>

<style scoped>
.legal > h1:first-child {
    margin-top: 0;
}
</style>
