<template>
    <div>
        <transition name="slide">
            <div v-if="$store.state.showFlyout" class="flyout" v-click-outside="closeFlyout">
                <div>
                    <button
                        type="button"
                        :aria-label="$t('navigation.ariaFlyoutClose')"
                    >
                        <ios-close-icon w="40px" h="40px" @click="closeFlyout" class="flyout--close-button" />
                    </button>
                    <nav class="flyout__navigation" @click="closeFlyout">
                        <router-link to="/" class="no-animation">Home</router-link>
                        <router-link to="/lebenslauf" class="no-animation">{{ $t('navigation.cv') }}</router-link>
                        <router-link to="/portfolio" class="no-animation">Portfolio</router-link>
                    </nav>
                </div>
                <div class="flyout__footer">
                    <theme-switch @change="onToggleSwitch" :checked="$store.state.lightTheme">
                        <ios-sunny-icon w="20px" h="20px" class="switch-icon" />
                        <ios-moon-icon w="20px" h="20px" class="switch-icon" />
                    </theme-switch>
                    <div>
                        <a @click="changeLang('de')" :class="{'router-link-active': langIsGerman, 'flyout__footer--lang': !langIsGerman}">de</a> /
                        <a @click="changeLang('en')" :class="{'router-link-active': !langIsGerman, 'flyout__footer--lang': langIsGerman}">en</a> 
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="$store.state.showFlyout" class="content-overlay" />
        </transition>
    </div>
</template>

<script>
import IosCloseIcon from "vue-ionicons/dist/ios-close.vue";
import ThemeSwitch from "./ThemeSwitch.vue";
import IosSunnyIcon from "vue-ionicons/dist/ios-sunny.vue";
import IosMoonIcon from "vue-ionicons/dist/ios-moon.vue";

export default {
    name: "FlyoutMenu",
    components: {
        IosCloseIcon,
        IosSunnyIcon,
        IosMoonIcon,
        ThemeSwitch
    },
    computed: {
        langIsGerman () {
            return this.$store.state.lang === "de";
        }
    },
    methods: {
        onToggleSwitch() {
            this.$store.commit("changeColorTheme");
        },
        closeFlyout() {
            this.$store.commit("setFlyoutVisibility", false);
        },
        changeLang (switchTo) {
            if (switchTo === this.$store.state.lang) {
                return;
            }
            this.$root.$i18n.locale = switchTo;
            this.$store.commit('changeLang');
        }
    },
};
</script>

<style scoped>
.flyout {
    position: fixed;
    text-align: flex-end;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: var(--item-background);
    z-index: 10;
    border-left: 1px solid var(--item-border);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.flyout button {
    border: none;
    padding: 0;
    background: transparent;
    color: var(--text-light);
}
.flyout--close-button {
    margin: 10px 10% 0;
}
.flyout--close-button:hover {
    color: var(--text-dark);
    cursor: pointer;
}
.flyout__navigation {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.flyout__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px
}
.flyout__footer a {
    background: transparent;
}
.flyout__footer--lang:hover {
    cursor: pointer;
}
.flyout__navigation a,
.flyout__footer {
    padding: 30px calc(10% + 10px) 30px 50px;
}
.router-link-active {
    background: var(--background);
}

@media (min-width: 551px) {
    .flyout,
    .content-overlay {
        display: none;
    }
}
@media (min-width: 500px) {
    .flyout--close-button {
        margin: 10px 20% 0;
    }
    .flyout__navigation a,
    .flyout__footer {
        padding: 30px calc(20% + 10px) 30px 50px;
    }
}
.switch-icon {
    color: var(--golden-shower);
    margin-left: 3px;
}

.content-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background);
    opacity: 0.7;
    z-index: 9;
}

/* transition menu */
.slide-enter-active,
.slide-leave-active {
    transition: all 0.3s ease-in-out;
}
.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
/* transition overlay background */
.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
