<template>
    <div class="project-search">
        <input v-model="searchWord" @keyup.enter="onSearch" class="project-search__input" type="search" :placeholder="$t('projects.searchForProjects')" />
        <button
            v-if="searchWord"
            @click="deleteSearchWord"
            class="unbutton project-search__button project-search__button--reset"
            :title="$t('projects.removeSearchWord')"
            type="button"
        >
            <ios-close-icon w="30px" h="30px" />
        </button>
        <button @click="onSearch" class="project-search__button project-search__button--search" :title="$t('projects.search')" type="button">
            <ios-search-icon w="25px" h="25px" />
        </button>
    </div>
</template>
<script>
import IosSearchIcon from 'vue-ionicons/dist/ios-search.vue';
import IosCloseIcon from 'vue-ionicons/dist/ios-close.vue';
export default {
    name: 'ProjectSearch',
    components: {
        IosSearchIcon,
        IosCloseIcon
    },
    data() {
        return {
            searchWord: ''
        };
    },
    mounted () {
        if (this.$route.query.s) {
            this.searchWord = this.$route.query.s;
        }
    },
    methods: {
        onSearch() {
            this.$router.push({
                query: { 
                    ...this.$route.query,
                    s: this.searchWord || undefined
                },
            });
        },
        deleteSearchWord() {
            this.searchWord = '';
            this.onSearch();
        }
    }
}
</script>

<style scoped>
    .project-search {
        display: flex;
        gap: 12px;
        margin: 0 5px 40px;
    }
    .project-search__input {
        font-size: var(--font-size-small);
        background-color: var(--emo-gray);
        color: var(--text-light);
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: 1px solid var(--item-border);
        padding: 10px;
        font-family: var(--main-font);
    }
    .project-search__input:focus-visible {
        outline: 1px solid var(--text-light);
    }
    .project-search__button {
        font-size: var(--font-size-small);
        padding: 9px;
        white-space: nowrap;
        border-radius: 5px;
        font-family: var(--main-font);
        color: var(--text-light);
        border: none;
        display: flex;
        gap: 4px;
        align-items: center;
        min-width: 45px;
        justify-content: center;
    }
    .project-search__button:hover {
        filter: brightness(1.05);
        cursor: pointer;
    }
    .project-search__button--reset,
    .project-search__button--search:hover {
        border: 1px solid var(--text-light);
    }
    .project-search__button--reset {
        background-color: var(--emo-gray);
    }
    .project-search__button--search {
        background-color: var(--emo-gray);
        border: 1px solid var(--item-border);
    }
</style>