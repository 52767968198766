<template>
    <button class="share-project-link" @click="shareProject(project, projectId)" type="button">
        <ios-link-icon w="18px" h="18px" /><span>{{ $t("projects.shareLink") }}</span>
    </button>
</template>

<script>
import IosLinkIcon from "vue-ionicons/dist/ios-link.vue";
export default {
    name: "ShareProjectLink",
    components: {
        IosLinkIcon,
    },
    props: {
        project: {
            type: Object,
            required: true,
        },
        projectId: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.share-project-link {
    display: flex;
    white-space: nowrap;
    border: none;
    background: transparent;
    color: var(--text-light);
    font-family: var(--main-font);
    font-size: var(--font-size-small);
}
.share-project-link:hover {
    cursor: pointer;
    color: var(--text-dark);
}
.share-project-link span {
    margin-left: 10px;
}
</style>
