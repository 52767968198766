import { createWebHistory, createRouter } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import ResumeView from "@/views/ResumeView.vue";
import ProjectsView from "@/views/ProjectsView.vue";
import ProjectDetailsView from "@/views/ProjectDetailsView.vue";
import LegalView from "@/views/LegalView.vue";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import projectsDe from '../assets/texts/projects_de.json';

let lastActivePageTitle;
const routes = [
    {
        path: "/",
        name: "Home",
        component: HomeView,
        meta: {
            id: 1,
            title: "Home",
            localeName: "navigation.home",
            googleIndexed: true,
        },
    },
    {
        path: "/lebenslauf",
        name: "Lebenslauf",
        component: ResumeView,
        meta: {
            id: 2,
            title: "Lebenslauf",
            description: "Ein Überblick über meine Skills, Erfahrungen und beruflichen Stationen.",
            localeName: "navigation.cv",
            googleIndexed: true,
        },
    },
    {
        path: "/portfolio",
        name: "Portfolio",
        component: ProjectsView,
        meta: {
            id: 3,
            title: "Portfolio",
            description: "Ein Einblick in meine Projekte: Mein Portfolio bestehend aus entwickelten Websites, Apps, Designs und kreativen Lösungen, die meine Skills und Leidenschaft widerspiegeln.",
            localeName: "navigation.portfolio",
            googleIndexed: true,
        },
    },
    {
        path: "/portfolio/:id(\\d+)",
        name: "Projektdetails",
        component: ProjectDetailsView,
        meta: {
            title: "Projekt",
            localeName: "navigation.projectDetails",
            googleIndexed: true,
        },
        beforeEnter: (to, from, next) => {
            const id = parseInt(to.params.id);
            // if the id is more or less than the projects count return 404
            if (id >= 1 && id <= projectsDe.length) {
                next();
            } else {
                next({ name: 'PageNotFound', params: { pathMatch: encodeURI(to.path.substring(1))}});
            }
        },
    },
    {
        path: "/impressum",
        name: "Impressum",
        component: LegalView,
        meta: {
            title: "Impressum",
            localeName: "legal.imprint",
            googleIndexed: false,
        },
    },
    {
        name: "PageNotFound",
        path: "/:pathMatch(.*)",
        component: PageNotFoundView,
        meta: {
            title: "404",
            localeName: "navigation.pageNotFound",
            googleIndexed: false,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll smooth to top
        window.scrollTo({ top: 0, behavior: "smooth" });
    },
});

// switch the page title according to the route
router.beforeEach((to, from, next) => {
    // set the Pagetitle
    document.title = `${to.meta.title} • Armin Novacek`;

    // set the meta description
    document.querySelector('meta[name="description"]')?.setAttribute("content", to.meta.description || 'Hallo, mein Name ist Armin Novacek. Ich bin studierter Web- und Appdeveloper, sowie passionierter Webdesigner.');

    // set the google indexer meta tag
    const metaContent = to.meta.googleIndexed ? "index,follow" : "noindex,nofollow";
    document.head.querySelector("[name~=robots][content]").content = metaContent;
    next();
});

// switch the page title when tab is not active
document.addEventListener("visibilitychange", function() {
    if (document.hidden) {
        lastActivePageTitle = document.title;
        document.title = "Armin Novacek";
    } else {
        document.title = lastActivePageTitle || "Armin Novacek";
    }
});

export default router;
