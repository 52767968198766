/**
 * get the relative image path for image sources
 * @param {string} img
 * @returns
 */
export function getImgPath(folder, img, fileFormat = 'png') {
    return require("@/assets/images/" + folder + "/" + img + `.${fileFormat}`);
}

/**
 * change the current color theme for the website
 * @param {boolean} lightTheme
 * @returns
 */
export function changeColorTheme(lightTheme) {
    // get the root element to change all set css variables
    const root = document.documentElement;
    if (lightTheme) {
        root.style.setProperty("--background", "#fbfbfd");
        root.style.setProperty("--background-dark", "white");
        root.style.setProperty("--background-opacity", "#fbfbfd96");
        root.style.setProperty("--item-background", "white");
        root.style.setProperty("--item-border", "#f1f1f1");
        root.style.setProperty("--header-shadow", "#3a3a3a14");
        root.style.setProperty("--text-light", "#292929");
        root.style.setProperty("--text-dark", "#5a5a5a");
        root.style.setProperty("--sea-gull", "#737c9b");
        root.style.setProperty("--disabled-light-gray", "");
        root.style.setProperty("--disabled-gray", "rgb(185 185 185)");
        root.style.setProperty("--emo-gray", "rgb(193 193 209 / 44%)");
        root.style.setProperty("--golden-shower", "");
        root.style.setProperty("--error-red", "");
        root.style.setProperty("--rainbow", "linear-gradient(to right, #6B8DD6, #5d74db, #6c62b3, #8a5ab3)");
    } else {
        // set it back to default
        root.removeAttribute("style");
    }

    // write in local storage
    localStorage.setItem("lightTheme", lightTheme);
}

/**
 * set the current language for the website
 * @param {string} lang
 * @returns
 */
export function setLangInStorage(lang) {
    // write in local storage
    localStorage.setItem("lang", lang);
}

/**
 * Natively share the link to a project
 * @param {Object} project
 * @returns
 */
export function shareProject(project, projectId) {
    const url = `https://arminnovacek.com/portfolio/${projectId}`;
    const decodedTitle = htmlDecode(project.title);
    if (!navigator.share) {
        navigator.clipboard.writeText(url);
        this.$notify({
            title: "Danke fürs Teilen",
            text: `Der Link zum Projekt <em>${decodedTitle}</em> wurde in die Zwischenablage kopiert`,
            type: "success",
        });
        return;
    }
    const payload = {
        title: decodedTitle,
        text: `${this.$t("projects.shareLinkText")}: ${decodedTitle}`,
        url,
    };
    navigator.share(payload);
}

export function setScrollAnim(styleClasses) {
    const revealAnimation = () => {
        if (typeof ScrollReveal !== 'undefined') {
            setTimeout(() => {
                styleClasses.forEach(styleClass => {
                    window.ScrollReveal().reveal(styleClass, {
                        delay: 200,
                        duration: 400,
                        distance: "100px",
                        easing: "ease-in"
                    });
                });
            }, 0);
        } else {
            console.error('helper.js: ScrollReveal konnte nicht geladen werden.');
        }
    };

    if (window.ScrollReveal) {
        revealAnimation();
    } else {
        document.addEventListener('DOMContentLoaded', revealAnimation);
    }
}


function htmlDecode(input) {
    let doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}
