<template>
    <div class="labeled-item">
        <h2 class="labeled-item__label">{{ label }}</h2>
        <div v-for="(c, index) of content" :key="index" class="resume-collapsable-item">
            <a :href="c.img_url" target="_blank" class="no-animation">
                <img class="resume-collapsable-item__image" :src="getImgPath('resume', c.img_name, 'webp')" loading="lazy" :alt="c.img_name" width="50" height="50" />
            </a>
            <div class="resume-collapsable-item__text">
                <h3 class="resume-collapsable-item__title">{{ c.title }}</h3>
                <p class="resume-collapsable-item__subtitle">
                    <em
                        ><span v-html="c.subtitle"></span>
                        <span class="resume-collapsable-item__subtitle_additive"> • {{ c.subtitle_additive }}</span></em
                    >
                </p>
                <p class="resume-collapsable-item__time">
                    <em>{{ c.time }}</em>
                </p>
                <button
                    class="resume-collapsable-item__button"
                    v-if="c.tasks"
                    @click="() => (c.show_task = !c.show_task)"
                    type="button"
                >
                    <ios-close-icon v-if="c.show_task" w="25px" h="25px" />
                    <span v-if="$store.state.lang === 'en'">{{ c.show_task ? "Hide" : "Show" }}</span>
                    {{ buttonText }}
                    <span v-if="$store.state.lang === 'de'">{{ c.show_task ? "ausblenden" : "anzeigen" }}</span>
                </button>
                <transition name="slide-fade">
                    <div v-if="c.show_task">
                        <ul v-for="(task, index) of c.tasks" :key="index" class="resume-collapsable-item__tasks">
                            <li v-html="task"></li>
                        </ul>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import IosCloseIcon from 'vue-ionicons/dist/ios-close.vue';
export default {
    name: "ResumeCollapsableItem",
    components: {
        IosCloseIcon
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
        buttonText: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped>
.resume-collapsable-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
}
.resume-collapsable-item__title {
    margin-bottom: 7px;
    margin-top: 13px;
    font-size: var(--font-size-large);
    font-weight: initial;
}
.resume-collapsable-item__subtitle {
    margin: 10px 0;
}
.resume-collapsable-item__subtitle_additive {
    color: var(--text-dark);
}
.resume-collapsable-item__time {
    margin: 0;
    color: var(--text-dark);
}
.resume-collapsable-item__tasks {
    color: var(--text-dark);
    list-style: none;
    padding-left: 10px;
}
.resume-collapsable-item__tasks li {
    border-left: 1px solid var(--text-light);
    padding-left: 10px;
}
.resume-collapsable-item__image {
    width: 50px;
    height: 50px;
    border-radius: 7px;
    margin-top: 17px;
}
.resume-collapsable-item__image:hover {
    opacity: 0.7;
}
.resume-collapsable-item__text {
    width: 88%;
    padding-left: 20px;
}
.resume-collapsable-item__button {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    font-size: var(--font-size-small);
    color: var(--background);
    font-weight: bold;
    background-image: var(--rainbow);
    width: max-content;
    height: 30px;
    border-radius: 5px;
    border: none;
    font-family: var(--main-font);
    margin: 14px 0;
    transition: all 0.2s ease-in-out;
}
.resume-collapsable-item__button:hover {
    filter: brightness(1.05);
}
.fa-times {
    margin-right: 4px;
}

/* transition */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease-in-out;
}

.slide-fade-enter-from {
    opacity: 0;
    height: 0%;
    transform: translateY(-30px);
}
.slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-30px);
    height: 100%;
}
</style>
