<template>
  <div class="page-not-found">
    <h1>404</h1>
    <p>{{ $t('pageNotFound.pageNotFound') }}</p>
    <router-link to="/">{{ $t('pageNotFound.backToHome') }}</router-link>
  </div>
</template>

<script>
export default {
    name: "PageNotFoundView",
}
</script>

<style scoped>
.page-not-found {
    padding-bottom: 50px;
    text-align: center;
}
.page-not-found > h1 {
    font-size: 300px;
    line-height: 0;
}
.page-not-found > p {
    color: var(--text-dark);
}
@media (max-width: 750px) {
  .page-not-found > h1 {
    font-size: 135px;
    padding-bottom: 100px
  }
}
</style>