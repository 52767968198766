<template>
    <header
        :class="{ 'headroom--unpinned': scrolled && settingsInactive }"
        v-on:scroll="handleScroll"
        class="header headroom"
    >
        <div class="header__inner main__inner">
            <router-link to="/" class="no-animation header-logo__container header-menu--web">
                <img class="header-logo" src="@/assets/images/logo.webp" loading="lazy" alt="Website Logo" width="30" height="30" />
            </router-link>
            <nav class="header-menu header-menu--web">
                <router-link to="/">Home</router-link>
                <router-link to="/lebenslauf">{{ $t("navigation.cv") }}</router-link>
                <router-link to="/portfolio">Portfolio</router-link>
                <settings-menu @headroom-behavior="handleHeaderStickyness" />
            </nav>
            <div class="header-menu header-menu--mobile">
                <button
                    :class="{ 'header-menu__back--invisible': !!!this.$router.options.history.state.back }"
                    type="button"
                    :aria-label="$t('navigation.ariaBackButton')"
                    @click="navigateBack"
                >
                    <ios-arrow-round-back-icon
                        w="40px"
                        h="40px"
                        class="header-menu--icon"
                    />
                </button>
                <span>{{ mobileHeaderTitle ? $t(mobileHeaderTitle) : "" }}</span>
                <button
                    type="button"
                    @click="openFlyout"
                    :aria-label="$t('navigation.ariaFlyoutOpen')"
                >
                    <ios-menu-icon w="40px" h="40px" class="header-menu--icon" />
                </button>
            </div>
        </div>
    </header>
</template>

<script>
import SettingsMenu from "./SettingsMenu.vue";
import IosArrowRoundBackIcon from "vue-ionicons/dist/ios-arrow-round-back.vue";
import IosMenuIcon from "vue-ionicons/dist/ios-menu.vue";
export default {
    name: "HeaderComponent",
    components: {
        SettingsMenu,
        IosArrowRoundBackIcon,
        IosMenuIcon,
    },
    data() {
        return {
            showFlyout: false,
            limitPosition: 500,
            scrolled: false,
            lastPosition: 0,
            settingsInactive: true
        };
    },
    computed: {
        mobileHeaderTitle() {
            return this.$route.meta.localeName;
        }
    },
    methods: {
        navigateBack() {
            this.$router.back();
        },
        openFlyout() {
            this.$store.commit("setFlyoutVisibility", true);
        },
        handleScroll() {
            // don't hide header on mobile devices
            if (window.innerWidth <= 550) return;

            // move up
            if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
                this.scrolled = true;
            }
            // down
            if (this.lastPosition > window.scrollY) {
                this.scrolled = false;
            }
            this.lastPosition = window.scrollY;
        },
        handleHeaderStickyness (settingsShown) {
            this.settingsInactive = !settingsShown;
        }
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>

<style scoped>
.header {
    background: var(--background-dark);
    position: fixed;
    height: 60px;
    width: 100%;
    z-index: 5;
    box-shadow: 5px 5px 10px var(--header-shadow);
    border-bottom: 1px solid var(--item-border);
}
.header__inner {
    display: flex;
    height: 100%;
}
.header-menu {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 70%;
    max-width: 400px;
    margin: auto 0 auto auto;
    font-size: var(--font-size-small);
}
.header-menu a {
    color: var(--text-light);
}
.header-menu button {
    padding: 0;
    background: transparent;
    border: none;
    color: var(--text-light);
}
.header-logo {
    width: 30px;
    height: 30px;
}
.header-logo:hover {
    opacity: 0.7;
}
.header-logo__container {
    display: flex;
    align-items: center;
}
.header-menu--icon:hover {
    cursor: pointer;
    color: var(--text-dark) !important;
}
.header-menu__back--invisible {
    opacity: 0;
}
.header-menu--mobile {
    display: none;
}
.header-menu--mobile > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media (max-width: 550px) {
    .header-menu {
        max-width: 100%;
    }
    .header-menu--web {
        display: none;
    }
    .header-menu--mobile {
        display: flex;
        width: 100%;
        margin: auto 0 auto;
        justify-content: space-between;
    }
}

/* header animation */
.headroom--unpinned {
    transform: translateY(-100%);
    box-shadow: none;
}
.headroom {
    will-change: transform;
    transition: transform 200ms linear;
}
</style>
